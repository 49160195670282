<template>
  <div>
    <!--      <headtitle Otitle="人才申报"/>-->
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>人才申报</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 搜索组件 -->
      <div class="fl" style="width: 100%">
        <div class="searchtool">
          <el-form
            label-width="0"
            class="ruleForm"
            size="small"
            style="width: 100%;"
          >
            <v-row>
              <v-col cols="4">
                <el-form-item>
                  <el-select
                    v-model="PolicyID"
                    placeholder="政策名称"
                    filterable
                    @change="getDate"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in PolicyPage"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="1">
                <el-form-item>
                  <el-input
                    v-model.trim="searchName"
                    placeholder="姓名"
                    clearable
                  />
                </el-form-item>
              </v-col>

              <v-col cols="2">
                <el-form-item>
                  <el-select
                    v-model="currentStatus"
                    multiple
                    placeholder="状态"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in StatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="5">
                <el-form-item>
                  <el-date-picker
                    clearable
                    v-model="searchStartIntroductionTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="申报时间"
                    end-placeholder="申报时间"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                  <el-button
                    type="primary"
                    plain
                    class="ml-4"
                    size="mini"
                    @click="getDate"
                    icon="el-icon-search"
                    >查询
                  </el-button>
                  <el-button
                    type="primary"
                    plain
                    size="mini"
                    @click="requestRefresh2"
                    icon="el-icon-refresh"
                    >刷新
                  </el-button>
                </el-form-item>
              </v-col>
            </v-row>
          </el-form>
        </div>
      </div>
      <div class="hr-20"></div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="policyTitle"
          min-width="240px"
          label="政策标题"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag size="small" type="danger" v-if="!scope.row.policyTitle"
              >此申报对应政策表单已删除</el-tag
            >
            <span
              v-else
              @click="seeDateiled(scope.row)"
              style="cursor: pointer"
              >{{ scope.row.policyTitle }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="申请人" align="center">
          <template slot-scope="scope">{{ scope.row.ownerName }}</template>
        </el-table-column>
        <el-table-column label="申报时间" align="center">
          <template slot-scope="scope">{{
            scope.row.createTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column label="状态" width="200" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.currentStatus === 1">提交</el-tag>
            <el-tag v-else-if="scope.row.currentStatus === 11">单位通过</el-tag>
            <el-tag type="success" v-else-if="scope.row.currentStatus === 21"
              >管理员通过</el-tag
            >
            <el-tag v-else-if="scope.row.currentStatus === 10">单位驳回</el-tag>
            <el-tag v-else-if="scope.row.currentStatus === 20"
              >管理员驳回</el-tag
            >
            <el-tag v-else type="warning">待审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              v-if="!scope.row.policyTitle"
              :disabled="true"
            >
              查看详情
            </el-button>
            <el-button
              size="mini"
              :type="scope.row.currentStatus === 0 ? 'warning' : 'primary'"
              plain
              v-else
              @click="seedetailed(scope.row)"
              v-text="scope.row.currentStatus === 0 ? '办理' : '查看详情'"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        id="pagination"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        v-model="pages"
        @current-change="requestPage"
      ></el-pagination>
    </el-card>

    <el-dialog
      v-model="createDialog"
      :visible.sync="createDialog"
      width="75%"
      @close="handleClose"
    >
      <h2
        v-html="Title"
        style="margin: 0 24px 24px;text-align: center;font-weight: 400;color: #1192ee;"
      ></h2>
      <el-row :gutter="5">
        <el-col>
          <el-card v-if="PdfUrl"
            ><iframe
              :src="PdfUrl"
              frameborder="0"
              width="100%"
              height="600px"
            ></iframe
          ></el-card>
          <el-card v-loading="createLoading" shadow="never" v-else>
            <div class="text-title">填报信息</div>
            <div
              class="shenbxx"
              v-for="(item, k) in detailsData"
              :key="k"
              :style="'display:' + item.type"
            >
              <span v-if="item.label === '户籍所在地'" class="text-address">
                {{ item.label }}：
                <div class="text-address text-color">
                  {{ String(item.value) }}
                </div>
              </span>
              <span v-else>
                {{ item.label }}：
                <div class="text-color">{{ String(item.value) }}</div>
              </span>
            </div>
          </el-card>

          <div class="hr-10"></div>

          <el-card style="text-align: left;">
            <el-button
              type="primary"
              size="small"
              style="color: #fff; margin: 0 auto;display: block;"
              icon="el-icon-orange"
              @click="PdfPreview(tableData)"
              >申请材料查看/关闭</el-button
            >
            <el-timeline :reverse="false">
              <el-timeline-item
                v-for="(activity, index) in affairlist"
                :key="index"
                :timestamp="activity.modifiedTime"
              >
                详情 : {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
          </el-card>
        </el-col>
      </el-row>
      <div id="dialog-btn">
        <el-row type="flex" justify="center" v-if="currentStatus === 0">
          <el-button
            type="primary"
            size="small"
            class="text"
            @click="handle(11, '单位通过')"
            >办理</el-button
          >
          <el-button
            type="danger"
            size="small"
            class="text"
            @click="handle(10, '单位驳回')"
            >驳回</el-button
          >
          <el-button plain size="small" @click="handleClose">取消</el-button>
        </el-row>
        <el-row v-else>
          <!--          <el-button type="success">发出通知</el-button>-->
          <el-col :span="2" :offset="10">
            <el-tag type="success" v-if="detailstatus === 11">单位通过</el-tag>
            <el-tag type="success" v-else-if="detailstatus === 21"
              >管理员同意</el-tag
            >
            <el-tag type="danger" v-else-if="detailstatus === 10"
              >单位驳回</el-tag
            >
            <el-tag type="danger" v-else-if="detailstatus === 20"
              >管理员驳回</el-tag
            >
            <el-tag type="danger" v-else>已驳回</el-tag>
          </el-col>
          <el-col :span="2">
            <el-button type="info" size="small" plain @click="handleClose"
              >取消</el-button
            >
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog title="驳回理由" :visible.sync="dialogVisible" width="520px">
      <el-input
        type="textarea"
        :rows="5"
        v-model="conterNew"
        placeholder="请输入驳回理由"
        maxlength="50"
      ></el-input>
      <div style="font-size: 12px;float: left;overflow: hidden">
        字数不超过50字
      </div>
      <div style="margin-top: 40px"></div>
      <el-button type="primary" @click="requestReject" class="fr text"
        >确 定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "@/utils/config";

export default {
  components: {},
  data() {
    return {
      fileurl: configDate.fileurl,
      dialogVisible: false,
      conterNew: "",
      PdfUrl: "",
      current: 1,
      currentStatus: 0,
      createDialog: false,
      createLoading: false,
      page: 1,
      pages: 0,
      pageSize: 10,
      total: 0,
      searchKeyword: "",
      gridData: "",
      formData: {},
      talentEducation: "",
      form: {
        money: "",
        remarks: "",
      },
      tableData: [],
      PolicyID: "",
      PolicyPage: [],
      Title: "",
      detailsData: [],
      detailstatus: 0,
      filelis: [],

      searchName: "",
      searchStartIntroductionTime: [],
      StatusList: [
        {
          value: 2,
          label: "未审核",
        },
        {
          value: 10,
          label: "单位驳回",
        },
        {
          value: 11,
          label: "单位通过",
        },
        {
          value: 21,
          label: "管理员通过",
        },
        {
          value: 20,
          label: "管理员驳回",
        },
      ],

      putData: {
        id: "",
        status: [],
        currentStatus: "",
      },
      putStatus: {
        affairId: 0,
        memo: "单位通过！",
        ownerId: 0,
        status: 11,
      },
      tabletSatuses: {
        userId: "",
        affairId: "",
      },
      affairlist: [], //记录
    };
  },
  created() {
    this.getDate(); //初始化数据
    this.getPolicyPage();
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    //判断上传控件类型
    isFlis(name) {
      console.log(this.detailsData);
      let setType = name.indexOf("talent") != -1 ? false : true;
      return true;
    },
    requestStatus() {},
    //  驳回
    requestReject() {
      this.$axios
        .post("/affair/status", {
          affairId: this.tabletSatuses.affairId,
          content: this.conterNew,
          memo: "单位驳回",
          ownerId: this.tabletSatuses.userId,
          status: 10,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$root.success("办理成功");
            this.dialogVisible = false;
            this.createDialog = false;
            this.getDate();
          } else {
            this.$root.warn("办理失败");
            this.dialogVisible = false;
          }
        })
        .catch((error) => {
          this.dialogVisible = false;
          console.error(error);
        });
    },
    requestPage(value) {
      this.current = value;
      this.getDate();
      return;
      this.$axios
        .get("/affair/page", {
          params: {
            current: total,
            size: this.pageSize,
          },
        })
        .then((response) => {
          this.tableData = response.data.data.records;
          this.total = response.data.data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 刷新详情数据
    requestRefresh2() {
      this.PolicyID = "";
      this.currentStatus = [];
      this.searchName = "";
      this.searchStartIntroductionTime = [];
      this.getDate();
    },
    getDate() {
      let _currentStatus = this.currentStatus.toString();
      let _startTime = "";
      let _endTime = "";
      if (this.searchStartIntroductionTime) {
        _startTime = this.searchStartIntroductionTime[0];
        _endTime = this.searchStartIntroductionTime[1];
      }
      this.$axios
        .get("/affair/enterprise/page", {
          params: {
            policyId: this.PolicyID,
            name: this.searchName,
            currentStatus: _currentStatus == "0" ? "" : _currentStatus,
            startTime: _startTime,
            endTime: _endTime,
            enterpriseId: sessionStorage.getItem("ID"),
            current: this.current,
            size: this.pageSize,
          },
        })
        .then((response) => {
          console.log(response.data.data.records[0]);
          this.total = response.data.data.total;
          if (response.data.status === 200) {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
          } else if (response.data.status === 400) {
            if (this.current > 1) {
              --this.current;
              this.getDate();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 获取政策列表
    getPolicyPage() {
      this.$axios
        .get("/policy/page", {
          params: {
            current: 1,
            size: 100,
          },
        })
        .then((response) => {
          // this.PolicyPage = ;
          response.data.data.records.forEach((item) => {
            this.PolicyPage.push({
              value: item.id,
              label: item.title,
            });
          });
        });
    },

    //  查看详情
    seedetailed(row) {
      this.putStatus.ownerId = row.ownerId;
      const self = this;
      this.createDialog = true;
      this.$axios
        .get("/affair?id=" + row.id)
        .then((response) => {
          const policyId = response.data.data.policyId;
          const id = response.data.data.id;
          this.putData.id = response.data.data.id;
          this.putData.status = response.data.data.status;
          this.currentStatus = response.data.data.currentStatus;
          self.tabletSatuses.userId = response.data.data.ownerId;
          self.tabletSatuses.affairId = response.data.data.id;

          self.putStatus.affairId = response.data.data.id;
          self.putStatus.ownerId = response.data.data.ownerId;

          self.$axios
            .get("/policy?id=" + policyId)
            .then(function(res) {
              self.Title = res.data.data.title || "";
              let newarr = [];
              res.data.data.form.inputs.forEach(function(item) {
                console.log(item);
                // let ThisType =
                //   item.name.indexOf("Upload") !== -1 ? "none" : "blick";
                let ThisType = item.type === "file" ? "none" : "blick";
                if (
                  item.name == "dishonesty" ||
                  item.name == "socialSecurity" ||
                  item.name == "serviceDeclaration" ||
                  item.name == "recordRegistration" ||
                  item.name == "declarationAttachment"
                ) {
                  ThisType = "none";
                }
                newarr.push({
                  type: ThisType,
                  label: item.label,
                  value: "",
                  [item.name]: "",
                });
              });

              self.$axios
                .get(
                  "/affair/statuses?affairId=" +
                    self.tabletSatuses.affairId +
                    "&userId=" +
                    self.tabletSatuses.userId
                )
                .then((response) => {
                  self.affairStatusId =
                    response.data.data[response.data.data.length - 1].id;
                  self.affairlist = response.data.data;
                });

              //事务详情
              self.$axios
                .get("/affair?id=" + id)
                .then(function(res) {
                  let separate = [];
                  res.data.data.values.forEach(function(item) {
                    if (item.name.indexOf("Upload") !== -1) {
                      self.getFlelis(item.value);
                    } else if (
                      item.name == "dishonesty" ||
                      item.name == "socialSecurity" ||
                      item.name == "serviceDeclaration" ||
                      item.name == "recordRegistration" ||
                      item.name == "declarationAttachment"
                    ) {
                      self.getFlelis(item.value);
                    } else {
                      separate.push(item);
                    }
                  });
                  self.detailstatus = res.data.data.currentStatus;
                  //self.detailstatus = res.data.data.status.pop().status
                  separate.forEach(function(item, i) {
                    newarr.forEach(function(list, index) {
                      if (item.label === list.label) {
                        if (item.name === "talentCheckbox2") {
                          newarr[index].value = JSON.parse(item.value);
                        } else {
                          newarr[index].value = item.value;
                        }
                      }
                    });
                  });
                  //console.log(self.detailsData);
                })
                .catch(function(error) {
                  console.error(error);
                });
              self.detailsData = newarr;
            })
            .catch(function(error) {
              console.error(error);
            });
        })
        .catch((error) => {
          this.createLoading = false;
          console.error(error);
        });
    },
    //下载
    downloaded(path) {
      window.location.href = this.fileurl + path;
    },
    PdfPreview(data) {
      let { enterpriseId } = data[0];

      console.log(
        enterpriseId,
        this.putStatus.ownerId,
        this.tabletSatuses.affairId
      );
      if (!this.PdfUrl) {
        this.PdfUrl =
          configDate.url +
          "/export/preview?affairId=" +
          this.tabletSatuses.affairId +
          "&talentId=" +
          this.putStatus.ownerId +
          "&enterpriseId=" +
          enterpriseId;
      } else {
        this.PdfUrl = "";
        this.createDialog = false;
      }
    },
    getFlelis(ID) {
      var self = this;
      this.$axios.get("/file/record?id=" + ID).then(function(res) {
        self.filelis.push({
          name: res.data.data.memo,
          path: res.data.data.path,
        });
      });
    },

    seeDateiled(row) {
      this.$router.push({ path: "/declareitem/declarewz/" + row.policyId });
    },

    handleClose() {
      this.detailsData = [];
      this.filelis = [];
      this.PdfUrl = "";
      this.createDialog = false;
    },

    //办理
    handle(status, memo) {
      if (status === 10) {
        this.dialogVisible = true;
        return false;
      }
      const self = this;
      self.putData.currentStatus = status;
      if (confirm("确实要更新吗?")) {
        this.$axios.post("/affair/status", this.putStatus).then(function(res) {
          if (res.data.status === 200) {
            self.$notify({
              title: "审核成功",
              message: res.data.data,
              type: "success",
            });
            self.createDialog = !self.createDialog;
            self.getDate();
            return;
          } else {
            self.$notify({
              title: "失败",
              message: res.data.data,
              type: "info",
            });
            self.createDialog = !self.createDialog;
          }
          self.$router.back();
        });
      } else {
        self.$notify({
          title: "成功",
          message: "取消更新！",
          type: "info",
        });
      }
    },
  },

  computed: {
    // 控制显示的内容
    iSBoolean() {
      return function(value1, value2) {
        return value1 === value2;
      };
    },
  },
};
</script>

<style lang="less" scoped>
.speeditem {
  h4 {
    margin: 0;
  }

  p {
    margin: 3px;
  }
}

#dialog-btn {
  margin: 32px 0 0;
}

.text {
  color: #ffffff;
}

.span {
  background: #f3f3f3;
  display: block;
  height: 40px;
  font-size: 16px;
  text-align: center;
  padding: 0;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 320px;
  margin: 16px;
}

.shenbxx {
  font-size: small;
  margin-top: 8px;

  span {
    font-size: 15px;
    display: block;
    height: 35px;
    text-align: left;
    padding: 0 0 0 10px;
    line-height: 35px;
    float: left;
    width: 30%;
    min-width: 240px;
    margin: 9px;
    font-weight: bold;
  }
  .text-color {
    font-weight: lighter;
    display: inline-block;
  }

  .text-address {
    width: 80%;
    display: inline-block;
  }
}

#pagination {
  margin: 32px 0 16px 0;
}

.text-title {
  font-weight: bold;
  font-size: 17px;
}

.text {
  color: #ffffff;
}
</style>
